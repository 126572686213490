document.addEventListener("DOMContentLoaded", function () {
  // drop_drag staff
  $("#container_photo_sortable")
    .sortable({
      items: ".grid-item_photo",
      placeholder: "sortable-placeholder",
      forcePlaceholderSize: true,
      tolerance: "pointer",
      start: function (event, ui) {
        ui.placeholder.height(ui.item.height());
      },
      stop: function (event, ui) {
        var newOrder = $(this).sortable('toArray', { attribute: 'data-id' });
        $.ajax({
            url: "/xhr/shop/staffs/update_display_order",
            method: "POST",
            data: { order: newOrder },
            success: function (response) {
              console.log("display_order success");
            },
            error: function (xhr, status, error) {
              console.error(error);
            },
          });
      },
    })
    .disableSelection();
});
