document.addEventListener("DOMContentLoaded", function () {
    function favoriteStore(member, store) {
      const btnLike = $(".btn_favorite_store")
      $.ajax({
        url: "/xhr/likes/favorite_store",
        method: "POST",
        data: {
          member: member,
          store: store,
        },
        success: function (response) {
          if (response.success) {
            btnLike.addClass('cm_like_button-active');
          } else {
            btnLike.removeClass('cm_like_button-active');
          }
          btnLike.html(`${response.message}`)
        },
        error: function (xhr, status, error) {
          console.error(error);
        },
      });
    }

    function visitedStore(member, store) {
      const btnLike = $(".btn_visited_store")
      $.ajax({
        url: "/xhr/likes/visited_store",
        method: "POST",
        data: {
          member: member,
          store: store,
        },
        success: function (response) {
          if (response.success) {
            btnLike.addClass('cm_like_button-active');
          } else {
            btnLike.removeClass('cm_like_button-active');
          }
          btnLike.html(`${response.message}`)
        },
        error: function (xhr, status, error) {
          console.error(error);
        },
      });
    }

    function favoriteStaff(member, staff) {
      // const btnLike = $(".btn_favorite_staff")
      $.ajax({
        url: "/xhr/likes/favorite_staff",
        method: "POST",
        data: {
          member: member,
          staff: staff,
        },
        success: function (response) {
          // btnLike.html(`${response.message}`)
        },
        error: function (xhr, status, error) {
          console.error(error);
        },
      });
    }

    function visitedStaff(member, staff) {
      // const btnLike = $(".btn_visited_staff")
      $.ajax({
        url: "/xhr/likes/visited_staff",
        method: "POST",
        data: {
          member: member,
          staff: staff,
        },
        success: function (response) {
          // btnLike.html(`${response.message}`)
        },
        error: function (xhr, status, error) {
          console.error(error);
        },
      });
    }

    window.favoriteStoreOnchange = function (member, store) {
      favoriteStore(member, store);
    };
    window.visitedStoreOnchange = function (member, store) {
      visitedStore(member, store);
    };
    window.favoriteStaffOnchange = function (member, staff) {
      favoriteStaff(member, staff);
    };
    window.visitedStaffOnchange = function (member, staff) {
      visitedStaff(member, staff);
    };
  });