document.addEventListener("DOMContentLoaded", function () {
  const onTop = document.getElementById("js-on-top");
  const onLoad = document.getElementById("js-on-load");

  if (onTop) {
    onTop.addEventListener("click", function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  }

  if (onLoad) {
    onLoad.addEventListener("click", function () {
      location.reload();
    });
  }
});
