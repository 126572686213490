document.addEventListener("DOMContentLoaded", function () {
    const loading = `<p class="cm_center w-100" style="text-align:center;">
      <i class="fa-sharp fa-light fa-loader fa-spin fa-3x fa-fw"></i></p>`;
  
    function getSchedule(id, page) {
      $("#store_next_schedule").html(loading)
      const inc_date = $('#inc_date').val();
      $.ajax({
        url: "/xhr/store/show_today_schedules",
        method: "POST",
        data: {
          id: id,
          page: page,
          inc_date: inc_date,
        },
        success: function (response) {},
        error: function (xhr, status, error) {
          console.error(error);
        },
      });
    }

    function getStaff(id, page) {
      $("#store_next_staff").html(loading)
      $.ajax({
        url: "/xhr/store/show_staffs",
        method: "POST",
        data: {
          id: id,
          page: page,
        },
        success: function (response) {},
        error: function (xhr, status, error) {
          console.error(error);
        },
      });
    }

    function getPost(id, page) {
      $("#store_next_post").html(loading)
      $.ajax({
        url: "/xhr/store/show_posts",
        method: "POST",
        data: {
          id: id,
          page: page,
        },
        success: function (response) {},
        error: function (xhr, status, error) {
          console.error(error);
        },
      });
    }
    
    window.storeNextSchedule = function (id, page) {
      getSchedule(id, page);
    };

    window.storeNextStaff = function (id, page) {
      getStaff(id, page);
    };

    window.storeNextPost = function (id, page) {
      getPost(id, page);
    };

    if ($("#user_store_show").val()) {
      getSchedule($("#user_store_show").val(), 0);
      getStaff($("#user_store_show").val(), 0);
      getPost($("#user_store_show").val(), 0);
    }
  });
  