$(document).ready(function() {
  var controller = $('body').data('controller');
  if (controller === 'search') {
    $('.search-button').on('click', function(e) {
      const currentBtn = $(e.currentTarget);
      const keywordElm = currentBtn.closest('.search-wrapper').find('.search-input');
      const keywordValue = keywordElm.val().trim();
      const url = currentBtn.data('url');
      const loading = currentBtn.closest('.tab_content').find('.loading_tmp');
      const data = currentBtn.closest('.tab_content').find('.data');

      // reset data
      data.html('');
      loading.html(window.w_loading);

      $.ajax({
        method: 'POST',
        url: url,
        data: {
          keyword: keywordValue,
        },
      });
    });
  }
});
