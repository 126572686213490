$(document).ready(function() {
  const controller = $('body').data('controller');
  const action = $('body').data('action');
  if (controller === 'areas') {
    handleClickDayButton();
  }
});

const handleClickDayButton = () => {
  const dayButton = $('.day-button');
  const incDateParam = $('#inc_date');
  dayButton.on('click', (e) => {
    const currentButton = $(e.currentTarget);
    incDateParam.val(currentButton.data('index'));
    const prefecture_id = $('#prefecture_id').val();
    const start_time = $('#start_time').val();
    const end_time = $('#end_time').val();
    const area_id = $("#area_id").val();
    $("#container_schedules").html("");
    $("#schedules_loading").html(window.w_loading);

    $.ajax({
      url: "/xhr/area/schedule_by_time",
      method: "POST",
      data: {
        prefecture_id: prefecture_id,
        start_time: start_time,
        end_time: end_time,
        area_id: area_id,
        inc_date: currentButton.data('index')
      },
      success: function (response) {},
      error: function (xhr, status, error) {
        console.error(error);
      },
    });
  });
}
