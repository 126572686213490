document.addEventListener("DOMContentLoaded", function () {
  $(".member_change_form").click(function () {
    $(".member_login").animate({ height: "toggle", opacity: "toggle" }, "400");
    if ($('.register-form.member_login').css('display') === 'block') {
      $('.register-form.member_login').css('display', 'flex');
    }
  });

  $(".member_open_form_login").click(function () {
    $("#remodal_member_modal").removeClass("d-none");
  });
  
  $(".member_close_form_login").click(function () {
    $("#remodal_member_modal").addClass("d-none");
  });

  window.profileLikesOnChange = function () {
    $("#modal_profile").addClass("cm_profile_display_none");
    $("#remodal_member_modal").removeClass("d-none");
  };

  // login
  $("#check_login_member").click(function () {
    const checkEmail = $("#input_member_email").val().trim() == ''
    const checkPassword = $("#input_member_email").val().trim() == ''
    if (checkEmail) {
      $("#login_email_error").html("あなたのアカウントを入力してください")
    } else {
      $("#login_email_error").html("")
    }

    if (checkPassword) {
      $("#login_password_error").html("パスワードを入力してください")
    } else {
      $("#login_password_error").html("")
    }

    if (!checkEmail && !checkPassword) {
      $.ajax({
        url: "/xhr/members/check_login",
        method: "POST",
        data: {
          email: $("#input_member_email").val(),
          password: $("#input_member_password").val(),
        },
        success: function (response) {
          if (response.success) {
            $("#member_email").val($("#input_member_email").val());
            $("#member_password").val($("#input_member_password").val());
            $("#member_login").click();
          } else {
            $("#login_password_error").html(response.message)
          }
        },
        error: function (xhr, status, error) {
          console.error(error);
        },
      });
    }
  });

  if ($('#btn_forgot_password').length) {
    $('#btn_forgot_password').on('click', () => {
      $.ajax({
        url: "/xhr/members/forgot_password",
        method: 'POST',
        data: {
          email: $('#member_find_email').val(),
        },
        success: (_res) => {
          window.location = '/members/forgot'
        }
      })
    });
  }
});

