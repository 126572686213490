document.addEventListener("DOMContentLoaded", function () {
  const loading = `<p class="cm_center w-100" style="text-align:center;">
    <i class="fa-sharp fa-light fa-loader fa-spin fa-3x fa-fw"></i></p>`;
  window.w_loading = loading;

  window.areaOnchange = function (id, name) {
    $("#area_name").text(name);
    $(".push").removeClass("push");
    $(`#${id}`).addClass("push");

    $("#area_id").val(id.split("_")[1]);
    $("#story-container").html(loading);
    $("#container_schedules").html(``);
    // $("#schedules_loading").html(loading);
    $("#programming_content").html('');
    $("#post_loading").html(loading);
    $("#design_content").html('');
    $.ajax({
      url:
        "/xhr/area/show_area/" +
        $("#prefecture_id").val() +
        "/" +
        id.split("_")[1],
      method: "POST",
      data: {
        inc_date: $('#inc_date').val(),
      },
      success: function (response) {
        // $("#schedules_loading").html('');
        $("#post_loading").html('');
      },
      error: function (xhr, status, error) {
        console.error(error);
      },
    });
    // clear time
    $('input[type="radio"][name="changeTime"]').prop('checked', false);
    $('input[type="radio"][name="changeTime"]').first().prop('checked', true);
  };

  window.profileOnchange = function (id) {
    $("#modal_profile").removeClass("cm_profile_display_none");
    $("#model_profile_body").html(loading);
    $.ajax({
      url: "/xhr/area/show_profile/" + id,
      method: "POST",
      success: function (response) {},
      error: function (xhr, status, error) {
        console.error(error);
      },
    });
  };

  window.profileOnClose = function () {
    $("#modal_profile").addClass("cm_profile_display_none");
  };

  window.timeOnChange = function (prefecture, start_time, end_time) {
    $("#start_time").val(start_time)
    $("#end_time").val(end_time)
    $("#container_schedules").html("")
    $("#schedules_loading").html(loading)
    const inc_date = $('#inc_date').val();

    $.ajax({
      url: "/xhr/area/schedule_by_time",
      method: "POST",
      data: {
        prefecture_id: prefecture,
        start_time: start_time,
        end_time: end_time,
        area_id: $("#area_id").val(),
        inc_date: inc_date,
      },
      success: function (response) {},
      error: function (xhr, status, error) {
        console.error(error);
      },
    });
  };

  window.nextPageScheduleByArea = function () {
    const page = Number($("#schedule_page").val()) + 1
    const el_button = $("#btn_next_schedule");
    el_button.html(loading)
    $.ajax({
      url: el_button.data('url'),
      method: "POST",
      data: {
        prefecture_id: $("#prefecture_id").val(),
        start_time: $("#start_time").val(),
        end_time: $("#end_time").val(),
        area_id: $("#area_id").val(),
        page: page,
        inc_date: $('#inc_date').val(),
      },
      success: function (response) {
        $("#schedule_page").val(page)
      },
      error: function (xhr, status, error) {
        console.error(error);
      },
    });
  };

  window.nextPagePostByArea = function () {
    const page = Number($("#post_page").val()) + 1
    $("#btn_next_post").html(loading)
    $.ajax({
      url: "/xhr/area/next_page_post_by_area",
      method: "POST",
      data: {
        prefecture_id: $("#prefecture_id").val(),
        area_id: $("#area_id").val(),
        page: page,
      },
      success: function (response) {
        $("#post_page").val(page)
      },
      error: function (xhr, status, error) {
        console.error(error);
      },
    });
  };

  window.nextPageStoreByArea = function () {
    const page = Number($("#store_page").val()) + 1
    $("#btn_next_store").html(loading)
    $.ajax({
      url: "/xhr/area/next_page_store_by_area",
      method: "POST",
      data: {
        prefecture_id: $("#prefecture_id").val(),
        area_id: $("#area_id").val(),
        page: page,
      },
      success: function (response) {
        $("#store_page").val(page)
      },
      error: function (xhr, status, error) {
        console.error(error);
      },
    });
  };

  window.nextPageStoreBySearch = function (keyword, page) {
    $("#btn_next_store").html(loading)
    $.ajax({
      url: "/search_store",
      method: "POST",
      data: {
        keyword: keyword,
        page: page,
        type: 'next',
      }
    });
  };

  window.nextPageStaffBySearch = function (keyword, page) {
    $(".loading_tmp").html(loading)
    $.ajax({
      url: "/search_staff",
      method: "POST",
      data: {
        keyword: keyword,
        page: page,
        type: 'next',
      }
    });
  };
});
