document.addEventListener("DOMContentLoaded", function () {
  handleAvatar(
    "staff-avatar",
    "avatar-image-wid",
    "delete-staff-avatar",
    "/xhr/shop/staffs/delete_avatar/",
    "staff-avatar-size",
    "staff-img-error",
  );
  handleAvatar(
    "staff-image1",
    "image1-wid",
    "delete-image1",
    "/xhr/shop/staffs/delete_image1/",
    "staff-image1-size",
    "staff-img-error1",
  );
  handleAvatar(
    "staff-image2",
    "image2-wid",
    "delete-image2",
    "/xhr/shop/staffs/delete_image2/",
    "staff-image2-size",
    "staff-img-error2",
  );
  handleAvatar(
    "staff-image3",
    "image3-wid",
    "delete-image3",
    "/xhr/shop/staffs/delete_image3/",
    "staff-image3-size",
    "staff-img-error3",
  );
});

function handleAvatar(
  avatarId,
  imgContainerId,
  deleteBtnId,
  deleteUrlPath,
  imgSize,
  imgMessage,
) {
  const avatar = document.getElementById(avatarId);
  const imgContainer = document.querySelector(`#${imgContainerId}`);
  let imgAvatar;
  const deleteAvatar = document.getElementById(deleteBtnId);
  const postSize = document.getElementById(imgSize);
  const imgError = document.getElementById(imgMessage);

  if (imgContainer) {
    imgAvatar = imgContainer.querySelector("img");
  }

  if (avatar) {
    avatar.addEventListener("change", function (event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      if (!imgAvatar) {
        imgAvatar = document.createElement("img");
        imgContainer.appendChild(imgAvatar);
      }

      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;

        img.onload = function () {
          if (file.size > 1 * 1024 * 1024) {
            postSize.value = 1;
            imgError.style.display = "block";
          } else {
            postSize.value = 0;
            imgError.style.display = "none";
          }

          imgAvatar.src = e.target.result;
        };
      };

      reader.readAsDataURL(file);
    });
  }

  if (deleteAvatar) {
    deleteAvatar.addEventListener("click", function () {
      if (imgAvatar) {
        imgAvatar.src = "";
      }

      $.ajax({
        url: deleteUrlPath + $("#staff-id").val().toString(),
        method: "POST",
        success: function (response) {
          console.log("response", response);
          if (response.success) {
            avatar.value = "";
            alert("画像が削除されました。");
          } else {
            console.log("delete image error");
          }
        },
        error: function (xhr, status, error) {
          console.error(error);
        },
      });
    });
  }
}

document.addEventListener("DOMContentLoaded", function () {
  window.deleteByIdOnchange = function (id) {
    $(".staff-id").val(id)
  };

  window.deleteById = function() {
    $("#" + $(".staff-id").val()).click();
  };
});

