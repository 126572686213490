$(document).ready(function() {
  const controller = $('body').data('controller');
  const action = $('body').data('action');
  if (controller === "members/my_page") {
    let elmForm = '';
    let urlUpdateMemo = '';
    let urlUpdateStatus = '';
    let urlDelete = '';
    if (action === "therapist") {
      elmForm = '.form-staff';
      urlUpdateMemo = '/xhr/my_page/update_memo_visited_staff';
      urlUpdateStatus = '/xhr/my_page/update_status_visited_staff';
      urlDelete = '/xhr/my_page/delete_visited_staff';
    } else if (action === "like_shop") {
      elmForm = '.form-store';
      urlUpdateMemo = '/xhr/my_page/update_memo_visited_store';
      urlUpdateStatus = '/xhr/my_page/update_status_visited_store';
      urlDelete = '/xhr/my_page/delete_visited_store';
    }
    let boxType = '';
    let status = '';
    let backOrNG = '';

    $('.tab_item').on('click', (e) => {
      const currentTab = $(e.currentTarget);
      if (currentTab.attr('for') === 'programming') {
        boxType = '.like_box_in';
        status = 'NG';
        backOrNG = '.del_girl';
      } else if (currentTab.attr('for') === 'design') {
        boxType = '.like_box_in2';
        status = 'NOT_NG';
        backOrNG = '.back_girl';
      }
      handleButtonEdit(boxType, elmForm);
      handleButtonUpdate(boxType, elmForm, urlUpdateMemo);
      handleButtonNGorUndo(backOrNG, boxType, status, elmForm, urlUpdateStatus);
      handleButtonCloseLike(boxType, elmForm, urlDelete);
    });

    handleClickDayButton();
  }
});

// function
const handleButtonEdit = (boxType, elmForm) => {
  const btnEdit = $('.adit_girl');
  btnEdit.off('click');
  btnEdit.on('click', (e) => {
    const currentBtnEdit = $(e.currentTarget);
    const formTarget = currentBtnEdit.closest(boxType).find(elmForm).first();
    const elMemoArea = formTarget.find('.longer').first();
    const elMemoParagraph = currentBtnEdit.closest(boxType).find('.memo').first();
    formTarget.show();
    elMemoArea.val(elMemoParagraph.html());
    currentBtnEdit.prop('disabled', true);
  });
}

const handleButtonUpdate = (boxType, elmForm, urlUpdateMemo) => {
  const btnUpdate = $('.fish_girl');
  btnUpdate.off('click');
  btnUpdate.on('click', (e) => {
    const currentBtnUpdate = $(e.currentTarget);
    const formTarget = currentBtnUpdate.closest(boxType).find(elmForm).first();
    const btnEdit = currentBtnUpdate.closest(boxType).find('.adit_girl').first();
    const elMemoParagraph = currentBtnUpdate.closest(boxType).find('.memo').first();
    const elMemoArea = formTarget.find('.longer').first();
    $.ajax({
      url: urlUpdateMemo,
      method: 'POST',
      data: {
        id: formTarget.data('id'),
        memo: elMemoArea.val()
      },
      success: (_res) => {
        formTarget.hide();
        btnEdit.prop('disabled', false);
        elMemoParagraph.html(elMemoArea.val());
        elMemoArea.val('');
      },
      error: (_xhr, _status, error) => {
        console.log(error);
      }
    });
  });
}

const handleButtonNGorUndo = (elButton, boxType, status, elmForm, urlUpdateStatus) => {
  const btnNG = $(elButton);
  btnNG.off('click');
  btnNG.on('click', (e) => {
    const currentBtnNG = $(e.currentTarget);
    const formTarget = currentBtnNG.closest(boxType).find(elmForm).first();
    $.ajax({
      url: urlUpdateStatus,
      method: 'POST',
      data: {
        id: formTarget.data('id'),
        status: status
      },
      error: (_xhr, _status, error) => {
        console.log(error);
      }
    });
  });
}

const handleButtonCloseLike = (boxType, elmForm, urlDelete) => {
  const btnCloseLike = $('.close_like');
  btnCloseLike.off('click').on('click', (e) => {
    const currentBtnCloseLike = $(e.currentTarget);
    const likeBoxIn = currentBtnCloseLike.closest(boxType);
    const formTarget = likeBoxIn.find(elmForm).first();
    $.ajax({
      url: urlDelete,
      method: 'POST',
      data: {
        id: formTarget.data('id')
      },
      success: (_res) => {
        likeBoxIn.remove();
      },
      error: (_xhr, _status, error) => {
        console.log(error);
      }
    });
  });
}

const handleClickDayButton = () => {
  const dayButton = $('.day-button');
  const incDateParam = $('#inc_date');
  dayButton.on('click', (e) => {
    const currentButton = $(e.currentTarget);
    incDateParam.val(currentButton.data('index'));

    $('#page').val(0);
    $('#container_schedules').html('');
    $("#btn_next_schedule").html(window.w_loading)
    $.ajax({
      url: "/xhr/members/load_staff",
      method: "POST",
      data: {
        page: 0,
        inc_date: currentButton.data('index'),
      },
      success: function (response) {},
      error: function (xhr, status, error) {
        console.error(error);
      },
    });
  });
}
