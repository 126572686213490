/* eslint no-console:0 */
import Rails from '@rails/ujs'
import * as ActiveStorage from "@rails/activestorage"
import $ from 'jquery'
import 'jquery-ui-dist/jquery-ui'
import 'popper.js'
import 'bootstrap'
require('@nathanvda/cocoon')

import 'stylesheets/base'
import './store'
import './drop_drag'
import './staff'
import './home'
import './user_area'
import './user_store'
import './member'
import './likes'
import './my_page'
import './areas'
import './stores'
import './search'

global.$ = $;

Rails.start()
ActiveStorage.start()
