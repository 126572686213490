$(document).ready(function() {
  const controller = $('body').data('controller');
  const action = $('body').data('action');
  if (controller === 'stores') {
    handleClickDayButton();
  }

  if (controller === 'store/staffs') {
    // handle error
    // when action is create or update
    if (['create', 'update'].includes(action)) {
      const errElm = $('.alert.alert-error');
      errElm.fadeIn();
      errElm.fadeTo(3000, 500).slideUp(500);

      $('.alert.alert-error .close').on('click', function() {
        errElm.stop(true, true).fadeOut();
      });
    }

    if (action === 'edit') {
      const successElm = $('.alert.alert-success');
      if (successElm.data('success')) {
        successElm.fadeIn();
        successElm.fadeTo(3000, 500).slideUp(500);
      }

      $('.alert.alert-success .close').on('click', function() {
        successElm.stop(true, true).fadeOut();
      })
    }
  }
});

const handleClickDayButton = () => {
  const dayButton = $('.day-button');
  const incDateParam = $('#inc_date');
  dayButton.on('click', (e) => {
    const currentButton = $(e.currentTarget);
    incDateParam.val(currentButton.data('index'));

    $('#page').val(0);
    $('#store_container_schedules').html('');
    $("#store_next_schedule").html(window.w_loading)
    $.ajax({
      url: "/xhr/store/show_today_schedules",
      method: "POST",
      data: {
        id: $('#user_store_show').val(),
        page: $('#page').val(),
        inc_date: currentButton.data('index'),
      },
      success: function (response) {},
      error: function (xhr, status, error) {
        console.error(error);
      },
    });
  });
}
