$(document).on("click", "#hamburger", function () {
  var sidebar = document.getElementById("sidebar");
  sidebar.classList.toggle("hidden");
});

document.addEventListener("DOMContentLoaded", function () {
  const avatarInput = document.getElementById("store-avatar-input");
  const storeImg = document.querySelector("#store-img-wid");
  let imgPreview;
  const deleteShopAvatar = document.getElementById("store-delete-avatar");
  const imageError = document.getElementById("image-avatar-error");
  const postSize = document.getElementById("store-image-size");

  if (storeImg) {
    imgPreview = storeImg.querySelector("img");
  }

  if (avatarInput) {
    avatarInput.addEventListener("change", function (event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      if (!imgPreview) {
        imgPreview = document.createElement("img");
        storeImg.appendChild(imgPreview);
      }

      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;

        img.onload = function () {
          if (file.size > 1 * 1024 * 1024) {
            // avatarInput.value = "";
            if (imageError) {
              imageError.style.display = "block";
            }
            postSize.value = 1;
            imgPreview.src = e.target.result;
          } else {
            if (imageError) {
              imageError.style.display = "none";
            }
            postSize.value = 0;
            imgPreview.src = e.target.result;
          }
        };
      };

      reader.readAsDataURL(file);
    });
  }

  if (deleteShopAvatar) {
    deleteShopAvatar.addEventListener("click", function () {
      $.ajax({
        url: "/xhr/shop/" + $("#store-avatar-id").val(),
        method: "POST",
        success: function (response) {
          console.log("response", response);
          if (response.success) {
            imageError.style.display = "none";
            avatarInput.value = "";
            if (imgPreview) {
              imgPreview.src = "";
            }
            alert("画像が削除されました。");
          } else {
            console.log("delete image error");
          }
        },
        error: function (xhr, status, error) {
          console.error(error);
        },
      });
    });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  const avatarInput = document.getElementById("post-image-input");
  const storeImg = document.querySelector("#post-image-wid");
  let imgPreview;
  const deletePostImg = document.getElementById("delete-post-image");
  const imgError = document.getElementById("post_img_error");
  const postSize = document.getElementById("post-image-size");

  if (storeImg) {
    imgPreview = storeImg.querySelector("img");
  }

  if (avatarInput) {
    avatarInput.addEventListener("change", function (event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      if (!imgPreview) {
        imgPreview = document.createElement("img");
        storeImg.appendChild(imgPreview);
      }

      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;

        img.onload = function () {
          if (file.size > 1 * 1024 * 1024) {
            postSize.value = 1;
            imgError.style.display = "block";
          } else {
            postSize.value = 0;
            imgError.style.display = "none";
          }

          imgPreview.src = e.target.result;
        };
      };

      reader.readAsDataURL(file);
    });
  }

  if (deletePostImg) {
    deletePostImg.addEventListener("click", function () {
      if (imgPreview) {
        imgPreview.src = "";
      }

      $.ajax({
        url: "/xhr/shop/post/" + $("#post-image-id").val(),
        method: "POST",
        success: function (response) {
          console.log("response", response);
          if (response.success) {
            avatarInput.value = "";
            // if (imgPreview) {
            //   imgPreview.src = "";
            // }
            alert("画像が削除されました。");
          } else {
            console.log("delete image error");
          }
        },
        error: function (xhr, status, error) {
          console.error(error);
        },
      });
    });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  const scheduleBtn = document.getElementById("schedule_btn");
  const scheduleNextBtn = document.getElementById("schedule_weekday");

  if (scheduleBtn) {
    scheduleBtn.addEventListener("click", function (event) {
      scheduleNextBtn.click();
    });
  }
});